import * as React from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../../../components/layout"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import { formatPrice } from "../../../utils/format-price"
import { Seo } from "../../../components/seo"
import { CgChevronRight as ChevronIcon } from "react-icons/cg"
import { productOptions } from "../../../utils/static-data"
import {
  productBox,
  container,
  header,
  productImageWrapper,
  productImageList,
  productImageListItem,
  noImagePreview,
  optionsWrapper,
  priceValue,
  selectVariant,
  breadcrumb,
  addToCartStyle,
  productDescription,
  addOnOptionsWrapper,
  selectAddOnOptions,
  freeformNamesWrapper,
  textareaFreeformNames,
  singleNameWrapper,
  inputSingleName,
  rowSmallImages
} from "./product-page.module.css"

function createMarkup(innerHtml) {
  return { __html: innerHtml };
}

export default function Product({ data: { product } }) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    descriptionHtml,
    images,
    images: [firstImage],
    tags,
  } = product

  const { letterAndNameColors, lettersOfAlphabet, stainColors, colorArray, standingFamilyColors, standingFamilyPersonOptions, sportsVariantCombinations, sportsColors, sportsBorders, sportsIcons, armedForcesBranches, armedForcesNames, namePrefixOptions} = productOptions

  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const [letterColor, setLetterColor] = React.useState("")
  const [letter, setLetter] = React.useState("")

  const [freeformNames, setFreeformNames] = React.useState("");
  const [singleName, setSingleName] = React.useState("");
  const [nameColor, setNameColor] = React.useState("");
  const [stainColor, setStainColor] = React.useState("");

  const [bankBackgroundColor, setBankBackgroundColor] = React.useState("");
  const [bankLetterBorderColor, setBankLetterBorderColor] = React.useState("");
  const [bankNameBackgroundColor, setBankNameBackgroundColor] = React.useState("");
  const [bankNameForegroundColor, setBankNameForegroundColor] = React.useState("");

  const [standingFamilyColor, setStandingFamilyColor] = React.useState("");
  const [standingFamilyPerson1Option, setStandingFamilyPerson1Option] = React.useState("");
  const [standingFamilyPerson2Option, setStandingFamilyPerson2Option] = React.useState("");

  const [sportsVariant, setSportsVariant] = React.useState("");
  const [sportsColor, setSportsColor] = React.useState("");
  const [sportsIcon, setSportsIcon] = React.useState("");
  const [sportsBorder, setSportsBorder] = React.useState("");
  const [sportsName, setSportsName] = React.useState("");
  const [sportsNumber, setSportsNumber] = React.useState("");
  const [sportsTeam, setSportsTeam] = React.useState("");
  const [showTeamNameField, setShowTeamNameField] = React.useState(false);

  const [armedForcesBranch, setArmedForcesBranch] = React.useState("");
  const [armedForcesName, setArmedForcesName] = React.useState("");

  const [namePrefixOption, setNamePrefixOption] = React.useState("");

  const [currentImage, setCurrentImage] = React.useState(images[0]);

  const defaultSportsName = "(Optional) Enter Name Here";
  const defaultSportsNumber = "(Optional) Enter Number Here";
  const defaultSportsTeam = "Enter Team Name Here";

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {

        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  const handleLetterChangeEvent = (event) => {
    const value = event.target.value

    setLetter(value)
  }

  const handleLetterColorChangeEvent = (event) => {
    const value = event.target.value

    setLetterColor(value)
  }

  const handleStainColorChangeEvent = (event) => {
    const value = event.target.value

    setStainColor(value)
  }

  const handleFreeformNamesChange = (event) => {
    const value = event.target.value

    setFreeformNames(value)
  }

  const handleSingleNameChange = (event) => {
    const value = event.target.value

    setSingleName(value)
  }

  const handleNameColorChangeEvent = (event) => {
    const value = event.target.value

    setNameColor(value)
  }

  const handleBankBackgroundColorChangeEvent = (event) => {
    const value = event.target.value

    setBankBackgroundColor(value)
  }

  const handleBankLetterBorderColorChangeEvent = (event) => {
    const value = event.target.value

    setBankLetterBorderColor(value)
  }

  const handleBankNameBackgroundColorChangeEvent = (event) => {
    const value = event.target.value

    setBankNameBackgroundColor(value)
  }

  const handleBankNameForegroundColorChangeEvent = (event) => {
    const value = event.target.value

    setBankNameForegroundColor(value)
  }

  const handleCurrentImageChangeEvent = (image) => {
    const value = image

    setCurrentImage(value)
  }

  const handleStandingFamilyColorChangeEvent = (event) => {
    const value = event.target.value;

    setStandingFamilyColor(value);
  }

  const handleStandingFamilyPerson1ChangeEvent = (event) => {
    const value = event.target.value;

    setStandingFamilyPerson1Option(value);
  }

  const handleStandingFamilyPerson2ChangeEvent = (event) => {
    const value = event.target.value;

    setStandingFamilyPerson2Option(value);
  }

  const handleSportsVariantChangeEvent = (event) => {
    const value = event.target.value;

    setSportsVariant(value);
  }

  const handleSportsColorChangeEvent = (event) => {
    const value = event.target.value;

    setSportsColor(value);
  }

  const handleSportsIconChangeEvent = (event) => {
    const value = event.target.value;

    setSportsIcon(value);
  }

  const handleSportsBorderChangeEvent = (event) => {
    const value = event.target.value;

    setSportsBorder(value);

    value === "Team Name" ? setShowTeamNameField(true) : setShowTeamNameField(false);
  }

  const handleSportsNameChangeEvent = (event) => {
    const value = event.target.value;

    setSportsName(value);
  }

  const handleSportsNumberChangeEvent = (event) => {
    const value = event.target.value;

    setSportsNumber(value);
  }

  const handleSportsTeamChangeEvent = (event) => {
    const value = event.target.value;

    setSportsTeam(value);
  }

  const handleArmedForcesBranchChangeEvent = (event) => {
    const value = event.target.value;

    setArmedForcesBranch(value);
  }

  const handleArmedForcesNameChangeEvent = (event) => {
    const value = event.target.value;

    setArmedForcesName(value);
  }

  const handleNamePrefixOptionChangeEvent = (event) => {
    const value = event.target.value;

    setNamePrefixOption(value);
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = images.length > 1

  const hasLetters = tags.includes('select-letter-with-color');
  const hasLettersOnly = tags.includes('select-letter-only');
  const hasFreeformNames = tags.includes('select-freeform-names');
  const hasSingleName = tags.includes('select-single-name');
  const hasNameColor = tags.includes('select-name-color');
  const hasStainColor = tags.includes('select-stain-color');
  const hasBankColorChoices = tags.includes('select-bank-color-choices');
  const hasStandingFamilyBaseOptions = tags.includes('standing-family-base');
  const hasStandingFamilyExtraOptions = tags.includes('standing-family-extra');
  const hasSportsOptions = tags.includes('sports-options');
  const hasArmedForcesOptions = tags.includes('armed-forces-options');
  const hasNamePrefixOptions = tags.includes('select-name-prefix');

  return (
    <Layout>
      {firstImage ? (
        <Seo
          title={title}
          description={description}
          image={getSrc(images[0].gatsbyImageData)}
        />
      ) : undefined}
      <div className={container}>
        <div className={productBox}>
          {hasImages && (
            <div className={productImageWrapper}>
              <div
                role="group"
                aria-label="gallery"
                aria-describedby="instructions"
              >
                <ul className={productImageList}>
                  <li
                    key={`product-image-full-${currentImage.id}`}
                    className={productImageListItem}
                  >
                    <GatsbyImage
                      objectFit="contain"
                      loading={currentImage === 0 ? "eager" : "lazy"}
                      alt={
                        currentImage.altText
                          ? currentImage.altText
                          : `Product Image of ${title} #${currentImage + 1}`
                      }
                      image={currentImage.gatsbyImageData}
                    />
                  </li>
                </ul>
              </div>
              {hasMultipleImages && (
                <div className={rowSmallImages} id="instructions">
                  {images.map((image, index) => (
                    <button onClick={() => handleCurrentImageChangeEvent(image)} key={`product-image-small-${image.id}`}>
                      <GatsbyImage
                        objectFit="contain"
                        loading={index === 0 ? "eager" : "lazy"}
                        alt={
                          image.altText
                            ? image.altText
                            : `Product Image of ${title} #${index + 1}`
                        }
                        image={image.gatsbyImageData}
                      />
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
          {!hasImages && (
            <span className={noImagePreview}>No Preview image</span>
          )}
          <div>
            <div className={breadcrumb}>
              <Link to={product.productTypeSlug}>{product.productType}</Link>
              <ChevronIcon size={12} />
            </div>
            <h1 className={header}>{title}</h1>
            <div dangerouslySetInnerHTML={createMarkup(descriptionHtml)} className={productDescription} />
            <h2 className={priceValue}>
              <span>{price}</span>
            </h2>
            <fieldset className={optionsWrapper}>
              {hasVariants &&
                options.map(({ id, name, values }, index) => (
                  <div className={selectVariant} key="selectVariant">
                    <select
                      aria-label="Variants"
                      onChange={(event) => handleOptionChange(index, event)}
                    >
                      <option value="">{`Select ${name}`}</option>
                      {variants.map((variant) => variant.availableForSale && (
                        <option value={variant.title} key={`${name}-${variant.title}`}>
                          {variant.title}
                        </option>)
                      )}

                    </select>
                  </div>
                ))}
            </fieldset>
            {hasLetters && (
              <fieldset className={addOnOptionsWrapper}>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Letter Color"
                    onChange={(event) => handleLetterColorChangeEvent(event)}
                  >
                    <option value="">Select Letter Color</option>
                    {letterAndNameColors.map((color) => (
                      <option value={color} key={`LetterColor-${color}`}>
                        {color}
                      </option>)
                    )}
                  </select>
                </div>
              </fieldset>
            )
            }
            {(hasLetters || hasLettersOnly) && (
              <fieldset className={addOnOptionsWrapper}>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Letter"
                    onChange={(event) => handleLetterChangeEvent(event)}
                  >
                    <option value="">Select Letter</option>
                    {lettersOfAlphabet.map((letter) => (
                      <option value={letter} key={`Letter-${letter}`}>
                        {letter}
                      </option>)
                    )}
                  </select>
                </div>
              </fieldset>
            )
            }
            {hasFreeformNames && (
              <fieldset className={freeformNamesWrapper}>
                <div className={textareaFreeformNames}>
                  <textarea
                    aria-label="Names for Ornament"
                    defaultValue="Enter Names Here"
                    id="freeformNames"
                    name="freeformNames"
                    rows="4" cols="44"
                    onChange={(event) => handleFreeformNamesChange(event)}
                  />
                </div>
              </fieldset>
            )
            }
            {hasNameColor && (
              <fieldset className={addOnOptionsWrapper}>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Name Color"
                    onChange={(event) => handleNameColorChangeEvent(event)}
                  >
                    <option value="">Select Name Color</option>
                    {letterAndNameColors.map((color) => (
                      <option value={color} key={`NameColor-${color}`}>
                        {color}
                      </option>)
                    )}
                  </select>
                </div>
              </fieldset>
            )
            }
            {hasStainColor && (
              <fieldset className={addOnOptionsWrapper}>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Stain Color"
                    onChange={(event) => handleStainColorChangeEvent(event)}
                  >
                    <option value="">Select Stain Color</option>
                    {stainColors.map((stain) => (
                      <option value={stain} key={`StainColor-${stain}`}>
                        {stain}
                      </option>)
                    )}
                  </select>
                </div>
              </fieldset>
            )
            }
            {hasNamePrefixOptions && (
              <fieldset className={addOnOptionsWrapper}>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Name Prefix"
                    onChange={(event) => handleNamePrefixOptionChangeEvent(event)}
                  >
                    <option value="">Select Name Prefix</option>
                    {namePrefixOptions.map((option) => (
                      <option value={option} key={`NamePrefixOption-${option}`}>
                        {option}
                      </option>)
                    )}
                  </select>
                </div>
              </fieldset>
            )
            }
            {hasSingleName && (
              <fieldset className={singleNameWrapper}>
                <div className={inputSingleName}>
                  <input
                    type="text"
                    aria-label="Names for Ornament"
                    defaultValue="Enter Name Here"
                    id="singleName"
                    name="singleName"
                    onChange={(event) => handleSingleNameChange(event)}
                  />
                </div>
              </fieldset>
            )
            }
            {hasBankColorChoices && (
              <div key="bankColorChoicesInfoWrapper">
                <fieldset className={addOnOptionsWrapper}>
                  <div className={selectAddOnOptions}>
                    <select
                      aria-label="Select Background Color"
                      onChange={(event) => handleBankBackgroundColorChangeEvent(event)}
                    >
                      <option value="">Select Background Color</option>
                      {colorArray.map((color) => (
                        <option value={color} key={`Background-${color}`}>
                          {color}
                        </option>)
                      )}
                    </select>
                  </div>
                </fieldset>
                <fieldset className={addOnOptionsWrapper}>
                  <div className={selectAddOnOptions}>
                    <select
                      aria-label="Select Letter Border Color"
                      onChange={(event) => handleBankLetterBorderColorChangeEvent(event)}
                    >
                      <option value="">Select Letter Border Color</option>
                      {colorArray.map((color) => (
                        <option value={color} key={`LetterBorder-${color}`}>
                          {color}
                        </option>)
                      )}
                    </select>
                  </div>
                </fieldset>
                <fieldset className={addOnOptionsWrapper}>
                  <div className={selectAddOnOptions}>
                    <select
                      aria-label="Select Name Background Color"
                      onChange={(event) => handleBankNameBackgroundColorChangeEvent(event)}
                    >
                      <option value="">Select Name Background Color</option>
                      {colorArray.map((color) => (
                        <option value={color} key={`NameBackground-${color}`}>
                          {color}
                        </option>)
                      )}
                    </select>
                  </div>
                </fieldset>
                <fieldset className={addOnOptionsWrapper}>
                  <div className={selectAddOnOptions}>
                    <select
                      aria-label="Select Name Foreground Color"
                      onChange={(event) => handleBankNameForegroundColorChangeEvent(event)}
                    >
                      <option value="">Select Name Foreground Color</option>
                      {colorArray.map((color) => (
                        <option value={color} key={`NameForeground-${color}`}>
                          {color}
                        </option>)
                      )}
                    </select>
                  </div>
                </fieldset>
              </div>
            )
            }
            {hasStandingFamilyBaseOptions && (
              <fieldset className={addOnOptionsWrapper}>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Color"
                    onChange={(event) => handleStandingFamilyColorChangeEvent(event)}
                  >
                    <option value="">Select Color</option>
                    {standingFamilyColors.map((color) => (
                      <option value={color} key={`StandingFamilyColor-${color}`}>
                        {color}
                      </option>)
                    )}
                  </select>
                </div>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Person 1"
                    onChange={(event) => handleStandingFamilyPerson1ChangeEvent(event)}
                  >
                    <option value="">Select Person 1</option>
                    {standingFamilyPersonOptions.map((color) => (
                      <option value={color} key={`StandingFamilyPerson1-${color}`}>
                        {color}
                      </option>)
                    )}
                  </select>
                </div>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Person 2"
                    onChange={(event) => handleStandingFamilyPerson2ChangeEvent(event)}
                  >
                    <option value="">Select Person 2</option>
                    {standingFamilyPersonOptions.map((color) => (
                      <option value={color} key={`StandingFamilyPerson2-${color}`}>
                        {color}
                      </option>)
                    )}
                  </select>
                </div>
              </fieldset>
            )
            }
            {hasStandingFamilyExtraOptions && (
              <fieldset className={addOnOptionsWrapper}>
                <div className={selectAddOnOptions}>
                  <select
                    aria-label="Select Color"
                    onChange={(event) => handleStandingFamilyColorChangeEvent(event)}
                  >
                    <option value="">Select Color</option>
                    {standingFamilyColors.map((color) => (
                      <option value={color} key={`StandingFamilyColor-${color}`}>
                        {color}
                      </option>)
                    )}
                  </select>
                </div>
              </fieldset>
            )
            }
            {hasSportsOptions && (
              <div key="hasSportsOptionsWrapper">
                <div key="sportsVariantAndBackgroundInfoWrapper">
                  <div className={productDescription}>
                    Select Gender and Sport Color
                  </div>
                  <fieldset className={addOnOptionsWrapper}>
                    <div className={selectAddOnOptions}>
                      <select
                        aria-label="Select Gender and Sport"
                        onChange={(event) => handleSportsVariantChangeEvent(event)}
                      >
                        {sportsVariantCombinations.map((color) => (
                          <option value={color} key={`SportsVariant-${color}`}>
                            {color}
                          </option>)
                        )}
                      </select>
                    </div>
                  </fieldset>
                  <div className={productDescription}>
                    Select Background Color
                  </div>
                  <fieldset className={addOnOptionsWrapper}>
                    <div className={selectAddOnOptions}>
                      <select
                        aria-label="Select Background Color"
                        onChange={(event) => handleSportsColorChangeEvent(event)}
                      >
                        {sportsColors.map((color) => (
                          <option value={color} key={`SportsColor-${color}`}>
                            {color}
                          </option>)
                        )}
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div key="sportsBorderInfoWrapper">
                  <div className={productDescription}>
                    Select Border Type
                  </div>
                  <fieldset className={addOnOptionsWrapper}>
                    <div className={selectAddOnOptions}>
                      <select
                        aria-label="Select Border"
                        onChange={(event) => handleSportsBorderChangeEvent(event)}
                      >
                        {sportsBorders.map((border) => (
                          <option value={border} key={`SportsBorder-${border}`}>
                            {border}
                          </option>)
                        )}
                      </select>
                    </div>
                  </fieldset>
                  {showTeamNameField && (
                    <div key="showTeamNameFieldActive">
                      <div className={productDescription}>
                        If you wish to include town name (e.g. Pea Ridge), please make sure to put that in the field along with the team name.
                      </div>
                      <fieldset className={singleNameWrapper}>
                        <div className={inputSingleName}>
                          <input
                            type="text"
                            aria-label="Team Name (Optional)"
                            defaultValue={defaultSportsTeam}
                            id="sportsTeam"
                            name="sportsTeam"
                            onChange={(event) => handleSportsTeamChangeEvent(event)}
                          />
                        </div>
                      </fieldset>
                    </div>
                  )}
                </div>
                <div className="sportsIconInfoWrapper">
                  <div className={productDescription}>
                    With or Without Icons
                  </div>
                  <fieldset className={addOnOptionsWrapper}>
                    <div className={selectAddOnOptions}>
                      <select
                        aria-label="With or Without Icons"
                        onChange={(event) => handleSportsIconChangeEvent(event)}
                      >
                        {sportsIcons.map((icon) => (
                          <option value={icon} key={`SportsIcons-${icon}`}>
                            {icon}
                          </option>)
                        )}
                      </select>
                    </div>
                  </fieldset>
                </div>
                <div key="sportsNameAndNumberWrapper">
                  <div className={productDescription}>
                    The name and number fields are optional.  If the sport does not have numbers, please leave the field blank.
                  </div>
                  <fieldset className={singleNameWrapper}>
                    <div className={inputSingleName}>
                      <input
                        type="text"
                        aria-label="Name (Optional)"
                        defaultValue={defaultSportsName}
                        id="sportsName"
                        name="sportsName"
                        onChange={(event) => handleSportsNameChangeEvent(event)}
                      />
                    </div>
                  </fieldset>
                  <fieldset className={singleNameWrapper}>
                    <div className={inputSingleName}>
                      <input
                        type="text"
                        aria-label="Number (Optional)"
                        defaultValue={defaultSportsNumber}
                        id="sportsNumber"
                        name="sportsNumber"
                        onChange={(event) => handleSportsNumberChangeEvent(event)}
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            )}
            {hasArmedForcesOptions && (
              <div key="armedForcesOptions">
                <fieldset className={addOnOptionsWrapper}>
                  <div className={selectAddOnOptions}>
                    <select
                      aria-label="Select Branch"
                      onChange={(event) => handleArmedForcesBranchChangeEvent(event)}
                    >
                      <option value="">Select Branch</option>
                      {armedForcesBranches.map((branch) => (
                        <option value={branch} key={`ArmedForcesBranch-${branch}`}>
                          {branch}
                        </option>)
                      )}
                    </select>
                  </div>
                </fieldset>
                <fieldset className={addOnOptionsWrapper}>
                  <div className={selectAddOnOptions}>
                    <select
                      aria-label="Select Name"
                      onChange={(event) => handleArmedForcesNameChangeEvent(event)}
                    >
                      <option value="">Select Name</option>
                      {armedForcesNames.map((name) => (
                        <option value={name} key={`ArmedForcesName-${name}`}>
                          {name}
                        </option>)
                      )}
                    </select>
                  </div>
                </fieldset>
              </div>
            )
            }
            <div className={addToCartStyle}>
              <NumericInput
                aria-label="Quantity"
                onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                onChange={(event) => setQuantity(event.currentTarget.value)}
                value={quantity}
                min="1"
                max="20"
              />
              <AddToCart
                variantId={productVariant.storefrontId}
                quantity={quantity}
                available={available}
                letterColor={letterColor}
                letter={letter}
                freeformNames={freeformNames}
                singleName={singleName}
                nameColor={nameColor}
                stainColor={stainColor}
                bankBackgroundColor={bankBackgroundColor}
                bankLetterBorderColor={bankLetterBorderColor}
                bankNameBackgroundColor={bankNameBackgroundColor}
                bankNameForegroundColor={bankNameForegroundColor}
                standingFamilyColor={standingFamilyColor}
                standingFamilyPerson1Option={standingFamilyPerson1Option}
                standingFamilyPerson2Option={standingFamilyPerson2Option}
                sportsVariant={sportsVariant}
                sportsColor={sportsColor}
                sportsIcon={sportsIcon}
                sportsBorder={sportsBorder}
                sportsName={sportsName}
                sportsNumber={sportsNumber}
                sportsTeam={sportsTeam}
                armedForcesBranch={armedForcesBranch}
                armedForcesName={armedForcesName}
                namePrefixOption={namePrefixOption}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      descriptionHtml
      vendor
      handle
      productType
      productTypeSlug: gatsbyPath(
        filePath: "/products/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      tracksInventory
      storefrontId
      images {
        # altText
        id
        gatsbyImageData(layout: CONSTRAINED, width: 640)
      }
      variants {
        availableForSale
        inventoryQuantity
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
  }
`
