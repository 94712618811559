export const productOptions = {
    "stainColors": [
        "Barn Red",
        "Worn Navy",
        "Willow Gray",
        "Black Cherry",
        "Kona",
        "Jacobean",
        "Golden Oak",
        "Early American",
        "Special Walnut",
        "Cherry",
        "Natural"
    ],
    "lettersOfAlphabet": [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
    ],
    "states": [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
    ],
    "letterAndNameColors": [
        "White",
        "Black",
        "Natural Stain",
        "Red Stain"
    ],
    "colorArray": [
        "Black",
        "White",
        "Red",
        "Orange",
        "Yellow",
        "Green",
        "Blue",
        "Purple",
        "Gold",
        "Silver"
    ],
    "standingFamilyColors": [
        "Distressed White",
        "Medium Brown Stain"
    ],
    "standingFamilyPersonOptions": [
        "Person A",
        "Person B",
        "Person C",
        "Person D",
        "Person E",
        "Person F",
        "Person G"
    ],
    "sportsVariantCombinations": [
        "Male Baseball",
        "Male Basketball",
        "Male Football",
        "Male Golf",
        "Male Hockey",
        "Male Lacrosse",
        "Male Marching Band",
        "Male Martial Arts",
        "Male Soccer",
        "Male Swimming",
        "Male Tennis",
        "Male Track",
        "Male Volleyball",
        "Male Wrestling",
        "Female Basketball",
        "Female Cheerleading",
        "Female Football",
        "Female Golf",
        "Female Gymnastics",
        "Female Hockey",
        "Female Lacrosse",
        "Female Marching Band",
        "Female Martial Arts",
        "Female Soccer",
        "Female Softball",
        "Female Swimming",
        "Female Tennis",
        "Female Track",
        "Female Volleyball",
        "Female Wrestling"
    ],
    "sportsColors": [
        "Black",
        "Red",
        "Orange",
        "Yellow",
        "Green",
        "Blue",
        "Navy",
        "Purple",
        "Gold",
        "Silver"
    ],
    "sportsBorders": [
        "Key Words",
        "Team Name"
    ],
    "sportsIcons": [
        "With Icons",
        "Without Icons"
    ],
    "armedForcesBranches": [
        "Air Force",
        "Army",
        "Coast Guard",
        "Marine",
        "Navy"
    ],
    "armedForcesNames": [
        "Dad",
        "Mom",
        "Wife"
    ],
    "namePrefixOptions": [
        "Miss",
        "Ms.",
        "Mrs.",
        "Mr."
    ]
};